import revive_payload_client_yhavcZIXqT from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4._69f363c20909a5327c3a3f2fe83a5bca/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_4TTazIkrPt from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4._69f363c20909a5327c3a3f2fe83a5bca/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_D8YO6slHPS from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4._69f363c20909a5327c3a3f2fe83a5bca/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_9pH1YoAoYx from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4._69f363c20909a5327c3a3f2fe83a5bca/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_r2X1DPVk4A from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4._69f363c20909a5327c3a3f2fe83a5bca/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_RxtC3vrw2F from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4._69f363c20909a5327c3a3f2fe83a5bca/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_fnIwG2LPYe from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4._69f363c20909a5327c3a3f2fe83a5bca/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_NZjHt5xfXC from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4._69f363c20909a5327c3a3f2fe83a5bca/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_SCxnNXXBvn from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4._69f363c20909a5327c3a3f2fe83a5bca/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_MWXYq6RBwx from "/app/node_modules/.pnpm/nuxt-bugsnag@8.3.1_@bugsnag+core@8.2.0_magicast@0.3.5_rollup@4.32.1/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import plugin_client_O82MEhe1N4 from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.32.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_wy0B721ODc from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import switch_locale_path_ssr_mT45stNwvP from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_@vue+compiler-dom@3.5.13_eslint@9.19.0_jiti@2.4.2__magicast@0.3.5_ro_02779253f1b5aa8df187a7fdead12b7f/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_sP2ErAuRgW from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_@vue+compiler-dom@3.5.13_eslint@9.19.0_jiti@2.4.2__magicast@0.3.5_ro_02779253f1b5aa8df187a7fdead12b7f/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_sSsrLhQ6gt from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_@vue+compiler-dom@3.5.13_eslint@9.19.0_jiti@2.4.2__magicast@0.3.5_ro_02779253f1b5aa8df187a7fdead12b7f/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_RcePnU4qy2 from "/app/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.32.1_vite@6.0.11_@types+node@22.13.1_jiti@2.4_efa65eea516f941fbb504be62e5cfd87/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import posthog_client_fUucxKWhZE from "/app/plugins/posthog.client.ts";
import socket_client_qBDI6bzE0L from "/app/plugins/socket.client.ts";
import ssg_detect_2JtUqKDEas from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_@vue+compiler-dom@3.5.13_eslint@9.19.0_jiti@2.4.2__magicast@0.3.5_ro_02779253f1b5aa8df187a7fdead12b7f/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_yhavcZIXqT,
  unhead_4TTazIkrPt,
  router_D8YO6slHPS,
  payload_client_9pH1YoAoYx,
  navigation_repaint_client_r2X1DPVk4A,
  check_outdated_build_client_RxtC3vrw2F,
  view_transitions_client_fnIwG2LPYe,
  chunk_reload_client_NZjHt5xfXC,
  components_plugin_KR1HBZs4kY,
  prefetch_client_SCxnNXXBvn,
  plugin_MWXYq6RBwx,
  plugin_client_O82MEhe1N4,
  plugin_wy0B721ODc,
  switch_locale_path_ssr_mT45stNwvP,
  route_locale_detect_sP2ErAuRgW,
  i18n_sSsrLhQ6gt,
  plugin_RcePnU4qy2,
  posthog_client_fUucxKWhZE,
  socket_client_qBDI6bzE0L,
  ssg_detect_2JtUqKDEas
]